
/**
 * 列表功能插件
 * 
 */
export default {
	_load: function( page, app ) {
		
		//页面索引
		let pageIndex = app._count.vue
		//本插件this对象
		let _this = this
		//缓存page对象
		this[pageIndex] = page
		
		//列表Setting配置
		let defaultSetting = {
			// addVisible: false,		//添加页面 显示控制
			// editVisible: false,		//编辑页面 显示控制
			openSearch: true,		//开启搜索
			openManage: false,		//开启列表管理列
			openAdd: false,			//开启添加
			openEdit: false,		//开启编辑
			openDelete: false,		//开启删除
			openDeleteBatch: false,	//开启批量删除
			openBottom: true,		//开启底部
			openQuick: false,		//开启底部便捷管理（批量删除 添加组）
			openPagination: true,	//开启底部分页
			tableFirstType: 'index',//数据列表第一列类型，selection 多选，index 条目编号，expand 展开按钮
			tableFirstWidth: 60,	//数据列表第一列宽度
			tableFirstLabel: '序号',//数据列表第一列标签
			tableHeight: 500,			//数据列表最大高度（已做自动适应控制）
			listTableManageWidth: 110,	//数据列表管理列宽度
			listTableManageLabel: '操作',//数据列表管理列标签名称
			// searchApi: '',	//可以指定搜索接口地址，不传则默认 moduleApi + 'search'
			// deleteApi: '',	//可以指定删除接口地址，不传则默认 moduleApi + 'delete'
			// orderApi: '',	//可以指定排序接口地址，不传则默认 moduleApi + 'changeOrder'
		}
		//完整列表模式
		if(page.setting.moduleType == 'full') {
			defaultSetting['addVisible'] = false
			defaultSetting['editVisible'] = false
			defaultSetting['openAdd'] = true
			defaultSetting['openEdit'] = true
			defaultSetting['openManage'] = true
			defaultSetting['openDelete'] = true
			defaultSetting['openQuick'] = true
			defaultSetting['openDeleteBatch'] = true
			defaultSetting['tableFirstType'] = 'selection'
		}
		//快捷选中列
		if(page.setting.tableFirstType == 'selection' && !page.setting.tableFirstWidth) {
			page.setting.tableFirstWidth = 40
		}
		//合并设置
		for(let name in defaultSetting) {
			if(page.setting[name] === undefined) {
				page.setting[name] = defaultSetting[name]
			}
		}
		
		//列表List配置
		let defaultList = {
			size: 15,	//每页显示条数
			total: 0,	//总条数
			index: 1,	//当前页
			load: true,	//是否已载入
			reload: true,//是否重新载入
			searching: false,//搜索中
			search: {},	//搜索配置
			data: []	//列表数据
		}
		for(let name in defaultList) {
			if(page.list[name] === undefined) {
				page.list[name] = defaultList[name]
			}
		}
		
		//清空临时变量
		defaultSetting = undefined
		defaultList    = undefined
		
		
		
		
		//搜索
		page._listSearch = function(){
			if(!this.setting.searchApi && !this.setting.moduleApi) return 
			if(this.list.searching) return false
			this.list.reload = true
			this.list.searching = true
			let searchForm = this._listSearchData()
			let apiUrl = this.setting.searchApi
			if(!apiUrl) apiUrl = this.setting.moduleApi + 'search'
			this._post(apiUrl, searchForm, (res)=>{
				this._listUpdate(res)
			}, {
				loading: false,
				complete: ()=>{
					this.list.searching = false
				}
			})
		}
		
		//删除
		if(page.setting.openDelete)
		page._listDelete = function(index){
			this._confirm('删除后无法恢复，是否继续？', ()=>{
				let apiUrl = this.setting.deleteApi
				if(!apiUrl) apiUrl = this.setting.moduleApi + 'delete'
				this._post(apiUrl, { id: this.list.data[index].id}, (res)=>{
					this._msg('删除成功')
					this.list.data.splice(index, 1)
				})
			})
		}
		
		//批量删除
		if(page.setting.openDeleteBatch)
		page._listDeleteBatch = function(){
			if(!this.list.selection) {
				return this._msg('请至少选择一项哦~')
			}
			this._confirm('删除后无法恢复，是否继续？', ()=>{
				let apiUrl = this.setting.deleteApi
				if(!apiUrl) apiUrl = this.setting.moduleApi + 'delete'
				this._post(apiUrl, { id: this.list.selection}, (res)=>{
					this._msg('删除成功')
					this._listSearch()
				})
			})
		}
		
		//展示添加
		if(page.setting.openAdd)
		page._listShowAdd = function() {
			this.setting.addVisible = true
		}
		
		//展示编辑
		if(page.setting.openEdit)
		page._listShowEdit = function(index) {
			if(!index) index = 0
			let editLine = this.list.data[index]
			this.setting.lastEditId = editLine.id
			this.setting.editVisible = true
		}
		
		//更新列表
		if(!page._listUpdate)
		page._listUpdate = function(res) {
			if(res.data.CSVUrl) {
				window.open(this._api.domain + '/Common/Open/download?file='+res.data.CSVUrl);
				return false;
			}
			this.list.data = res.data
			if(this.list.reload) {
				this.list.total = res.other.count
				this.list.infos = res.other.infos
			}
			if(res.other.options) {
				for(let name in res.other.options){
					this.options[name] = res.other.options[name]
				}
			}
			this.list.load = false
			this.list.reload = false
			this._refreshTableHeight()
		}
		
		//切换显示条数
		if(page.setting.openPagination)
		page._listChangeSize = function(size){
			if(_this[pageIndex].list.size == size) return false
			_this[pageIndex].list.size = size
			_this[pageIndex].list.reload = true
			if(_this[pageIndex].list.index == 1) {
				_this[pageIndex]._listSearch()
			}
		}
		
		//切换页码
		if(page.setting.openPagination)
		page._listChangeIndex = function(index){
			if(index == _this[pageIndex].list.index) return false
			_this[pageIndex].list.index = index
			_this[pageIndex].list.reload = false
			_this[pageIndex]._listSearch()
		}
		
		//获取搜索的提交数据
		page._listSearchData = function(){
			let form = { search: this.list.search, order: '', size: '', index: 0, load: 0, reload: 0};
			if(this.list.order) form.order = this.list.order
			if(this.list.size) form.size = this.list.size
			if(this.list.index) form.index = this.list.index
			if(this.list.load) form.load = this.list.load
			if(this.list.reload) form.reload = this.list.reload
			return form
		}
		
		//搜索重置
		if(page.setting.openSearch)
		page._listSearchReset = function(){
			this.$refs['_listForm'].resetFields()
			// @todo
			this.list.search.addTime = ''
			this._listSearch()
		}
		
		//选择行
		if(page.setting.tableFirstType == 'selection')
		page._listSelectionChange = function(items){
			let id = ''
			items.forEach((row)=>{
				id += ',' + row.id
			});
			if(id) id = id.substr(1)
			_this[pageIndex].list.selection = id
		}
		
		//切换排序
		page._listChangeOrder = function(index){
			let orderBy = this.list.data[index].orderBy
			if(!/^[1-9]\d{0,2}$/.test(orderBy)) return this._msg('请输入1~999之间的顺序值')
			let apiUrl = this.setting.orderApi
			if(!apiUrl) {
				apiUrl = this.setting.moduleApi + 'changeOrder'
			}
			this._post(apiUrl, { id: this.list.data[index].id, orderBy: orderBy}, (res)=>{
				this._msg('更新成功')
				this._listSearch()
			})
		}
		
		//刷新表格高度
		page._refreshTableHeight = function(){
			if(this.$refs._listTable) {
				let height = window.innerHeight - this.$refs._listTable.$el.offsetTop - (this.setting.openBottom?135:20)
				setTimeout(()=>{
					this.setting.tableHeight = height
				}, 10)
			}
		}
		
		//改变窗口大小
		window.onresize = function() {
			if(_this[pageIndex]) {
				_this[pageIndex]._refreshTableHeight()
			}
		}
		page._refreshTableHeight()
		page.setting.loaded = true
		
	}
}