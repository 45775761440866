
import { reactive } from 'vue'
import _list from '@/assets/js/list'

export default {
	components: {},
	props: [ 'endTime', 'mchId','mchType','settlementId' ],
	setup(props, context) {
		return {
			options: reactive({}),
			setting: reactive({
				moduleName: '订单数量',
				searchApi: '/Settlement/Bill/getMchBillBySltId',
				tableFirstType: 'selection',
				openBottom: false
			}),
			list: reactive({search: {mchType: 1}, data: []}),
		
		}
	},
	mounted() {
		this.list.search.endTime = this.endTime
		this.list.search.settlementId = this.settlementId
		this.list.search.mchId = this.mchId
		this.list.search.mchType = this.mchType
		this._load(this, _list)
		this._listSearch()
	},
	methods: {
	}
}
